import FinanceApi from '@/services/FinanceApi';

const getAll = async (config) => {
    return FinanceApi.get('/vinov/instructions', config);
}

const get = async (id) => {
    return FinanceApi.get('/vinov/instructions/' + id);
}

const del = async (id) => {
    return FinanceApi.delete('/vinov/instructions/' + id);
}

const store = async (data) => {
    return FinanceApi.post('/vinov/instructions/', data);
}

const update = async (id, data) => {
    return FinanceApi.put('/vinov/instructions/' + id, data);
}

const student = async () => {
    return FinanceApi.get('/vinov/instructions/student');
}
const storeStudent = async (data) => {
    return FinanceApi.post('/vinov/instructions/student', data);
}

const storeStudentFromEpayment = async (token, data) => {
    return FinanceApi.post('/epayment/vinov', data, {
        headers: {
            'epayment-token': token
        }
    });
}

const storeCertificateFromEpayment = async (token, data) => {
    return FinanceApi.post('/epayment/certificate-vinov', data, {
        headers: {
            'epayment-token': token
        }
    });
}
export default {
    get, getAll, store, update, del, student, storeStudent, storeStudentFromEpayment, storeCertificateFromEpayment
}
